@import '../../../../scss/theme-bootstrap';

.loyalty-cancellation_popup {
  .error_messages {
    text-align: center;
    margin-bottom: 10px;
  }
  .cancellation {
    &-loyalty-sign-in-component__form-header {
      text-align: center;
    }
    &-signin-form-container {
      .cancellation-loyalty-sign-in-component__form {
        &-data {
          margin-bottom: 30px;
          text-align: center;
        }
      }
      .form-item {
        input[type='text'],
        input[type='password'] {
          width: 100%;
        }
      }
      .sign-in-component__button-wrapper {
        text-align: center;
        @include breakpoint($landscape-up) {
          text-align: $rdirection;
        }
        .form-submit {
          padding: 6px 50px;
          @include breakpoint($landscape-up) {
            padding: 6px 30px;
          }
        }
      }
      .forget-password {
        margin: 20px 0 10px;
        text-align: $rdirection;
        @include breakpoint($landscape-up) {
          text-align: $ldirection;
        }
      }
    }
    &-confirmation-form-container {
      @include breakpoint($landscape-up) {
        width: 90%;
        margin: auto;
      }
      .cancellation-loyalty-sign-in-component__form {
        &-data {
          margin-bottom: 30px;
        }
      }
      .loyalty_popover__loyalty_cancel {
        &-button {
          margin-top: 2px;
          float: $ldirection;
        }
        &-text {
          margin-#{$ldirection}: 10px;
          width: 80%;
          float: $ldirection;
        }
      }
      .form-submit {
        margin-top: 20px;
        padding: 6px 30px;
      }
      .dont-cancel {
        margin-top: 15px;
      }
    }
    &-confirmation-success-form-container {
      @include breakpoint($landscape-up) {
        width: 90%;
        margin: auto;
      }
    }
  }
}
